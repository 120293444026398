import React, { useState, useEffect } from 'react';
import telegram from '../telegram';

const Notification = ({ message, onClose, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Вызываем haptic feedback при появлении уведомления
    if (type === 'success') {
      telegram.hapticFeedback('success');
    } else if (type === 'error') {
      telegram.hapticFeedback('error');
    }

    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); // Уведомление исчезнет через 3 секунды

    const closeTimer = setTimeout(() => {
      onClose(); // Закрываем уведомление
    }, 3500); // Даем анимации исчезновения завершиться

    return () => {
      clearTimeout(timer);
      clearTimeout(closeTimer);
    };
  }, [onClose, type]);

  const handleClick = () => {
    setVisible(false);
    setTimeout(onClose, 500); // Даем анимации времени на исчезновение
  };

  return (
    <div
      onClick={handleClick}
      className={`fixed top-0 left-0 w-full flex justify-center items-center p-4 transition-transform duration-500 ease-in-out ${visible ? 'translate-y-0' : '-translate-y-full'}`}
    >
      <div className={`bg-black bg-opacity-80 text-white rounded-2xl p-4 w-full max-w-md shadow-md flex items-center`}>
        {type === 'success' && (
          <span className="material-icons mr-2">check_circle</span>
        )}
        {type === 'error' && (
          <span className="material-icons mr-2">error</span>
        )}
        <p className="text-md font-normal text-left">{message}</p>
      </div>
    </div>
  );
};

export default Notification;
