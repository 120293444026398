import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import telegram from '../telegram';

export default function Company() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    telegram.setBackButtonHandler(navigate, '/');
    
    return () => {
        telegram.hideBackButton();
    };
  }, [navigate]);
  

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center">
      <div className={`transition-transform duration-500 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <span className="material-icons text-8xl text-white">lock</span>
      </div>
      <div className="text-center mt-4">
        <p className="text-gray-400">Здесь скоро что-то появится</p>
      </div>
    </div>
  );
}
