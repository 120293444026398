import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import telegram from '../telegram';

export default function InvestmentsCrypto() {
  const navigate = useNavigate();

  useEffect(() => {
    telegram.setBackButtonHandler(navigate, '/investments');
    
    return () => {
        telegram.hideBackButton();
    };
  }, [navigate]);

  const [cryptoAssets, setCryptoAssets] = useState([]);

  useEffect(() => {
    const fetchCryptoAssets = async () => {
      try {
        const response = await fetch('https://api0.20two.in/crypto-assets');
        const data = await response.json();
        setCryptoAssets(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchCryptoAssets();
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <div className="space-y-2">
        {cryptoAssets.map((asset) => (
          <NavLink 
            key={asset.id} 
            to={`/investments/crypto/${asset.id}`} // Путь к деталям криптовалюты
            className="bg-gray-800 rounded-xl p-4 flex justify-between items-center shadow-lg transition-transform duration-200 hover:scale-105" // Добавляем эффект при наведении
          >
            <div className="flex items-center">
              <img
                src={`https://cdn.20two.in/tradetoearn/crypto-assets/${asset.id}.png`}
                alt={asset.id}
                className="w-10 h-10 rounded-full border-2 border-gray-600 mr-4"
              />
              <span className="text-lg font-bold text-white">{asset.id}</span>
            </div>
            <span className="text-lg text-gray-300">{asset.currentPrice} $</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
