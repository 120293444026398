import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import telegram from '../telegram';

export default function Investments() {
  const navigate = useNavigate();

  useEffect(() => {
    telegram.setBackButtonHandler(navigate, '/');
    
    return () => {
        telegram.hideBackButton();
    };
  }, [navigate]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-start p-4 overflow-hidden">
      <div className="flex flex-col space-y-4 w-full max-w-md">
        {/* Блок для криптовалюты */}
        <NavLink to="/investments/crypto" className={`relative bg-gradient-to-r from-yellow-500 to-yellow-400 rounded-xl shadow-lg p-6 flex items-center transition-transform duration-500 ${isVisible ? 'translate-x-0' : '-translate-x-full'}`}>
          <span className="material-icons text-4xl text-white">currency_bitcoin</span>
          <div className="flex flex-col ml-4">
            <h2 className="text-lg font-bold text-white">Криптовалюта</h2>
            <p className="text-sm text-gray-100">Торгуйте криптоактивами</p>
          </div>
        </NavLink>

        {/* Блок для акций */}
        <NavLink to="/investments/stocks" className={`relative bg-gradient-to-r from-blue-500 to-blue-400 rounded-xl shadow-lg p-6 flex items-center transition-transform duration-500 ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}>
          <span className="material-icons text-4xl text-white">show_chart</span>
          <div className="flex flex-col ml-4">
            <h2 className="text-lg font-bold text-white">Акции</h2>
            <p className="text-sm text-gray-200">Инвестируйте в компании</p>
          </div>
        </NavLink>
      </div>

      {/* Секция Ваши активы */}
      <div className="mt-6 w-full max-w-md">
        <h3 className="text-lg font-bold text-white mb-2">Ваши активы</h3>
        <div className="bg-gray-800 rounded-xl shadow-lg p-4 flex flex-col space-y-3">
          {/* Пример актива 1 */}
          <div className="flex justify-between items-center text-white">
            <div className="flex items-center">
              <span className="material-icons text-2xl">currency_bitcoin</span>
              <span className="ml-2">BTC</span>
            </div>
            <div className="text-right">
              <p className="text-sm">2</p>
              <p className="text-sm">$120,000</p>
            </div>
          </div>
          {/* Пример актива 2 */}
          <div className="flex justify-between items-center text-white">
            <div className="flex items-center">
              <span className="material-icons text-2xl">show_chart</span>
              <span className="ml-2">AAPL</span>
            </div>
            <div className="text-right">
              <p className="text-sm">5</p>
              <p className="text-sm">$1,500</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
