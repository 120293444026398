import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import telegram from '../telegram';

Chart.register(...registerables);

export default function InvestmentsCryptoDetail() {
  const navigate = useNavigate();

  useEffect(() => {
    telegram.setBackButtonHandler(navigate, '/investments/crypto');
    
    return () => {
        telegram.hideBackButton();
    };
  }, [navigate]);

  const { id } = useParams();
  const [cryptoData, setCryptoData] = useState(null);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await fetch(`https://api0.20two.in/crypto-assets/${id}`);
        const data = await response.json();
        setCryptoData(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchCryptoData();
  }, [id]);

  if (!cryptoData) {
    return <p className="text-white">Загрузка...</p>;
  }

  const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // Смещение в миллисекундах

  const data = {
    labels: cryptoData.priceHistory.map(item => 
      new Date(new Date(item.timestamp).getTime() - timeZoneOffset).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    ),
    datasets: [
      {
        label: 'Цена',
        data: cryptoData.priceHistory.map(item => item.price),
        borderColor: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fill: true,
        borderWidth: 2,
        tension: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(2);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `Цена: $${tooltipItem.raw}`; // Отображаем цену при наведении
          },
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <h1 className="text-3xl font-bold text-white mb-4">{cryptoData.name}</h1>
      <div className="bg-gray-800 rounded-xl p-4 shadow-lg">
        <h2 className="text-lg font-bold text-white">Текущая цена:</h2>
        <p className="text-xl text-green-500">{cryptoData.currentPrice} $</p>
      </div>
      <div className="mt-4">
        {cryptoData.priceHistory.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <p className="text-gray-400">Нет доступных данных для графика.</p>
        )}
      </div>
    </div>
  );
}
