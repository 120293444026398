const requestTimestamps = new Map();

export const claimStartCapital = async (initData) => {
    const userId = JSON.parse(decodeURIComponent(new URLSearchParams(initData).get('user'))).id;
    const now = Date.now();

    if (requestTimestamps.has(userId) && now - requestTimestamps.get(userId) < 2000) {
        return { error: 'Too Many Requests', status: 429 };
    }

    requestTimestamps.set(userId, now);

    try {
        const response = await fetch('https://api0.20two.in/claim-start-capital', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ initData }),
        });

        const data = await response.json();
        return { data, status: response.status };
    } catch (error) {
        console.error('Ошибка сети:', error);
        return { error: 'Ошибка сети', status: 500 };
    }
};
