class TelegramService {
    constructor() {
        this.webApp = window.Telegram.WebApp;
        this.initData = this.webApp.initData;
        this.initDataUnsafe = this.webApp.initDataUnsafe;
        this.version = this.webApp.version;
        this.platform = this.webApp.platform;
        this.colorScheme = this.webApp.colorScheme;
    }

    setHeaderColor(color) {
        if (this.webApp) {
            this.webApp.setHeaderColor(color);
        }
    }

    setBackButtonHandler(navigate, path) {
        this.webApp.BackButton.show();
        this.webApp.BackButton.onClick(() => {
            navigate(path);
        });
    }

    hideBackButton() {
        this.webApp.BackButton.hide();
    }

    getInitData() {
        return this.initData || null;
    }

    getInitDataUnsafe() {
        return this.initDataUnsafe || null;
    }

    getPlatform() {
        return this.platform;
    }

    closeApp() {
        this.webApp.close();
    }

    showAlert(message) {
        this.webApp.showAlert(message);
    }

    showConfirm(message, callback) {
        this.webApp.showConfirm(message).then(callback);
    }

    hapticFeedback(type) {
        if (this.webApp) {
            switch (type) {
                case 'success':
                    this.webApp.HapticFeedback.notificationOccurred('success');
                    break;
                case 'error':
                    this.webApp.HapticFeedback.notificationOccurred('error');
                    break;
                // Можно добавить другие типы, если нужно
                default:
                    break;
            }
        }
    }
}

const telegram = new TelegramService();
export default telegram;
