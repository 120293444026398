import React, { useState } from 'react';
import { useUser } from '../contexts/UserContext';
import telegram from '../telegram';
import Notification from '../components/Notification'; // Подключаем компонент уведомления
import { claimStartCapital } from '../utils/api';

export default function Earn() {
  const { user, setUser } = useUser();
    const [isClaiming, setIsClaiming] = useState(false);
    const [notification, setNotification] = useState(null);

    const capitalization = user ? user.balance : 0;
    const formattedCapitalization = capitalization.toLocaleString();
    const companyName = user ? `${user.first_name}'s Invest Company` : "Инвест компания";
    const isStartCapital = user ? user.isStartCapital : true;

    const handleClaimStartCapital = async () => {
        if (!isClaiming) {
            setIsClaiming(true);
            const initData = telegram.getInitData();
            const { data, error, status } = await claimStartCapital(initData);

            if (status === 429) {
                setNotification({ message: 'Слишком много запросов. Попробуйте позже.', type: 'error' });
            } else if (data?.success) {
                setUser(data.user);
                setNotification({ message: 'Стартовый капитал успешно начислен!', type: 'success' });
            } else {
                setNotification({ message: error || 'Ошибка получения стартового капитала.', type: 'error' });
            }

            setIsClaiming(false);
        }
    };

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-start p-4">
      {notification && (
        <Notification 
          message={notification.message} 
          type={notification.type} 
          onClose={() => setNotification(null)} 
        />
      )}

      {/* Контейнер для названия компании */}
      <div className="bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-xl shadow-lg p-4 w-full max-w-md flex items-center space-x-2 mt-0">
        <span className="material-icons text-3xl">business</span>
        <div className="flex flex-col">
          <h1 className="text-xl font-semibold">{companyName}</h1>
        </div>
      </div>

      {/* Контейнер для карточки капитализации */}
      <div className="bg-gray-800 text-white rounded-xl shadow-lg p-6 mt-4 w-full max-w-md">
        <div className="flex flex-col items-start">
          <p className="text-sm font-medium opacity-75">Капитализация компании</p>
          <p className={`font-extrabold text-3xl mt-2`}>{formattedCapitalization} <span className="text-gray-400">$</span></p>
        </div>
      </div>

      {/* Новая секция для стартового капитала */}
      {!isStartCapital && (
          <div 
              className="relative bg-gradient-to-r from-green-500 to-teal-600 rounded-xl shadow-lg p-6 mt-4 w-full max-w-md flex items-center overflow-hidden cursor-pointer" 
              onClick={handleClaimStartCapital} // Изменено на handleClaimStartCapital
          >
              <span className="material-icons text-4xl text-white">card_giftcard</span>
              <div className="flex flex-col mx-4">
                  <div className="h-6 border-l-2 border-gray-300 opacity-50 mx-2"></div> 
              </div>
              <div className="flex flex-col">
                  <h2 className="text-lg font-bold text-white">Стартовый капитал</h2>
                  <p className="text-sm text-gray-200">Нажмите, чтобы получить!</p>
              </div>
          </div>
      )}

      {/* Секция Заданий */}
      <div className="relative bg-gray-800 rounded-xl shadow-lg p-6 mt-4 w-full max-w-md flex items-center overflow-hidden">
        <span className="material-icons text-4xl text-white">assignment</span>
        <div className="flex flex-col mx-4">
          <div className="h-6 border-l-2 border-gray-600 mx-2"></div>
        </div>
        <div className="flex flex-col">
          <h2 className="text-lg font-bold text-white">Задания для вас</h2>
          <p className="text-sm text-gray-400">Заработайте больше!</p>
        </div>
        <div className="absolute inset-0 border-2 border-gray-600 rounded-xl animate-pulse"></div> {/* Пульсирующая обводка */}
      </div>
    </div>
  );
}
