import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBar() {
  return (
    <nav className="fixed bottom-0 left-0 w-full bg-gray-800 bg-opacity-90 flex">
      <NavLink
        to="/investments"
        className={({ isActive }) =>
          `flex-1 text-center py-4 transition-colors duration-300 ${isActive ? "bg-gradient-to-br from-blue-500 to-purple-600 text-white shadow-lg" : "text-gray-300"}`
        }
        onClick={() => window.scrollTo(0, 0)} // Сброс прокрутки при нажатии
      >
        <div className="flex flex-col items-center">
          <span className="material-icons text-3xl">trending_up</span>
          <span className="block text-xs mt-1">Инвестиции</span>
        </div>
      </NavLink>

      <NavLink
        to="/"
        className={({ isActive }) =>
          `flex-1 text-center py-4 transition-colors duration-300 ${isActive ? "bg-gradient-to-br from-green-500 to-teal-600 text-white shadow-lg" : "text-gray-300"}`
        }
        onClick={() => window.scrollTo(0, 0)} // Сброс прокрутки при нажатии
      >
        <div className="flex flex-col items-center">
          <span className="material-icons text-3xl">monetization_on</span>
          <span className="block text-xs mt-1">Заработок</span>
        </div>
      </NavLink>

      <NavLink
        to="/company"
        className={({ isActive }) =>
          `flex-1 text-center py-4 transition-colors duration-300 ${isActive ? "bg-gradient-to-br from-pink-500 to-purple-600 text-white shadow-lg" : "text-gray-300"}`
        }
        onClick={() => window.scrollTo(0, 0)} // Сброс прокрутки при нажатии
      >
        <div className="flex flex-col items-center">
          <span className="material-icons text-3xl">business</span>
          <span className="block text-xs mt-1">Компания</span>
        </div>
      </NavLink>
    </nav>
  );
}
