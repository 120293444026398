import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext'; 
import Investments from './pages/Investments';
import InvestmentsCrypto from './pages/InvestmentsCrypto';
import InvestmentsCryptoDetail from './pages/InvestmentsCryptoDetail';
import Company from './pages/Company';
import Earn from './pages/Earn';
import NavBar from './components/NavBar';
import telegram from './telegram';
import LoadingScreen from './components/LoadingScreen';

const App = () => {
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(true); 
  const { setUser } = useUser();

  const validateUser = useCallback(async (initData) => {
      try {
          const response = await fetch('https://api0.20two.in/validate-telegram', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ initData }),
          });
          const data = await response.json();
          if (data.error) {
              console.error('Validation failed:', data.error);
              setAuthError(true);
              setLoading(false);
          } else if (data.success) {
              setUser(data.user);
          }
      } catch (error) {
          console.error('Ошибка при валидации:', error);
          setAuthError(true);
          setLoading(false); 
      }
  }, [setUser]); 

  useEffect(() => {
      const initData = telegram.getInitData();
      if (!initData) {
          console.error('Validation failed: No InitData');
          setAuthError(true);
          setLoading(false);
      } else {
        telegram.setHeaderColor('#111827');
        const startTime = Date.now();
        validateUser(initData).then(() => {
            const loadDuration = Date.now() - startTime;
            const minLoadTime = 1500;
            setTimeout(() => {
                setLoading(false);
            }, Math.max(0, minLoadTime - loadDuration)); 
        });
      }
  }, [validateUser]); 

    if (loading) {
        return <LoadingScreen />; 
    }

    if (authError) {
        return (
            <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center relative">
                <div className="transition-transform duration-500 translate-y-0">
                    <span className="material-icons text-8xl text-white">lock</span>
                </div>
                <div className="text-center mt-4">
                    <p className="text-gray-400">Неудачная авторизация</p>
                </div>
                <div className="absolute bottom-10 text-center">
                    <p className="text-gray-400 mt-2">
                        Считаете, что это не так?
                    </p>
                    <a href="https://t.me/xkiryl" className="text-white underline cursor-pointer">
                        Свяжитесь с нами
                    </a>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Earn />} />
                    <Route path="/investments" element={<Investments />} />
                    <Route path="/investments/crypto" element={<InvestmentsCrypto />} />
                    <Route path="/investments/crypto/:id" element={<InvestmentsCryptoDetail />} />
                    <Route path="/company" element={<Company />} />
                </Routes>
                <NavBar />
            </div>
        </Router>
    );
}

export default function AppWrapper() {
    return (
        <UserProvider>
            <App />
        </UserProvider>
    );
}